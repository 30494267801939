/* Poppins Font CDN */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/higher-jump');
@import url('https://fonts.cdnfonts.com/css/sf-espresso-shack');
/* Cookie Font CDN */
@import url('https://fonts.googleapis.com/css2?family=Cookie&family,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');
/* Montserrat Font CDN */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* overflow: hidden; */
  
}

body {
  /* background-color: #E2E8F0; */
  background-color: #fff;
  
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 99;
}

.loader img {
  width: 150px;
  height: 150px;
}