.about-heritage-section {
    max-width: 1440px;
    margin: auto;
    padding: 20px;
    background-color: #f8f9fa;
}

.about-container {
    margin: 0 auto;
    padding: 0px
}

.history-image,
.devghadh-baria-image,
.heritage-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.about-container h1 {
    color: #B81104;
    text-align: center;
    font-family: "Cookie";
    font-size: 50px;
}

.about-description {
    text-align: center;
    font-style: italic;
}

.description,
.history-section p,
.devghadh-baria-mahal-section p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

.mission-vision p {
    font-size: 18px;
    color: #444;
}

.royal-family p {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}

.contact-section p {
    font-size: 16px;
    color: #333;
}

.image-content {
    display: flex;
    gap: 20px;
}

.history-section h2 {
    color: #B81104;
    text-align: center;
    text-transform: uppercase;
}

.history-section p>a {
    color: cornflowerblue;
}

.image-content img,
.image-content .heritage-content {
    width: 50%;
    border-radius: 20px;
}

/* Responsive */
@media (max-width: 480px) {
    .image-content {
        flex-direction: column;
    }

    .image-content img,
    .image-content .heritage-content {
        width: 100%;
        border-radius: 20px;
    }
}